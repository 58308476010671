import { mapActions } from 'vuex'
import router from '../../plugins/router'
import SButton from '../../components/ui/s-button'

import happySolidIcon from '../../assets/images/issues/happy_icon.svg'
import confuseSolidIcon from '../../assets/images/issues/confuse_icon.svg'

export default {
  name: 'IssueFeedback',
  props: ['completed'],

  components: {
    SButton,
  },

  data() {
    return {
      code: router.currentRoute.params?.code,
      confuseSolidIcon,
      happySolidIcon,
      issueId: router.currentRoute.params?.issueId,
    }
  },

  methods: {
    ...mapActions(['updateIssue']),

    async positive() {
      if (this.completed) {
        return
      }

      await this.updateIssue({ completed: true, status: 'SOLVED' })
      this.$emit('issue:completed')
    },

    async negative() {
      if (this.completed) {
        return
      }

      await this.updateIssue({
        completed: false,
        status: 'NEW',
        replied: false,
      })
      this.$emit('issue:incomplete')
    },
  },
}
